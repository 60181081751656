<template>
  <div class="containerGeralBudget">
    <div>
      <b-row
        class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="align-items: center; justify-content: space-between"
      >
        <div class="titleHeader">
          <p class="title">
            NOVO ORÇAMENTO Nº
            {{ numberBudget || dataBudgetOfTheBudgetModal.number }}
          </p>
        </div>

        <div
          style="
            margin-right: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
          "
        >
          <label
            :style="`color: ${alterColorSituation}`"
            class="title m-0 p-0"
            >{{ getNameSituantionById(dataBudget.idSituacao) }}</label
          >
        </div>
      </b-row>

      <b-row class="mt-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
          <Input
            ref="data"
            label="Data"
            :labelFixed="true"
            type="date"
            :dataInput.sync="dataBudget.data"
          />
        </div>

        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
          <Input
            ref="dataVencimento"
            label="Data Vencimento"
            :labelFixed="true"
            type="date"
            :dataInput.sync="dataBudget.dataVencimento"
          />
        </div>

        <div class="col-sm-4 col-md-4 col-lg-5 col-xl-5 infinite">
          <InputInfinit
            label="Cliente"
            :isEdit="!!dataBudget.idCliente"
            :dataInput.sync="dataBudget.idCliente"
            :onSearch="fillCustomerComboBoxWithPaginate"
            :openCreateModal="() => openModals('modalCreateCustomer')"
            :findAll="createdNewCustomer"
            @selectItem="handleDataCustomers($event)"
          />
        </div>

        <div class="inputResponsive col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <InputSelect
            label="Funcionário"
            :items="dataEmployee"
            valueField="id"
            textField="nomeFuncionario"
            :dataSelect.sync="dataBudget.idFuncionario"
            :openCreateModal="() => openModals('modalCreateEmployee')"
            ref="refResponsavel"
          />
        </div>
      </b-row>
    </div>

    <hr />

    <div class="contentItens">
      <div class="responsiveItems mt-5">
        <b-row
          class="itemsBudget mt-5"
          v-for="(prod, indice) in dataBudget.products.filter(
            (item) => item.tipoProduto === 'normal'
          )"
          :key="prod.idList"
        >
          <div
            v-if="prod.tipoProduto === 'normal'"
            style="width: 30%; position: relative"
            size="sm"
          >
            <div
              style="
                position: absolute;
                top: -23px;
                right: 0;
                z-index: 9999;
                display: flex;
                gap: 0.5rem;
              "
            >
              <div
                v-if="prod.idProduto"
                style="display: flex; align-items: center; gap: 0.3rem"
              >
                <b-icon-back
                  v-if="!selectedItem.id"
                  class="btnReplaceAll"
                  @click="handleDuplicate(prod)"
                  v-b-tooltip.hover.top="'Clonar!'"
                  ref="replaceAll"
                />

                <b-icon-bookmarks-fill
                  v-if="!selectedItem.id"
                  tag="span"
                  class="btnReplaceAll"
                  @click="handleItemSelected(prod.idProduto, indice)"
                  v-b-tooltip.hover.top="'Substituir todos!'"
                  ref="replaceAll"
                />

                <div
                  v-else-if="selectedItem.id && selectedItem.idx === indice"
                  style="display: flex; gap: 0.5rem"
                >
                  <b-icon-check-square-fill
                    @click="applyReplaceAll"
                    style="background-color: green"
                    class="btnReplaceAll"
                    v-b-tooltip.hover.top="'Confirmar Substituição!'"
                  />

                  <b-icon-x-square-fill
                    @click="cancelReplaceAll"
                    style="background-color: red"
                    class="btnReplaceAll"
                    v-b-tooltip.hover.top="'Cancelar Substituição!'"
                  />
                </div>
              </div>

              <button
                class="btnsComposition"
                @click="
                  handleShowCompositionItem(
                    prod.idProduto,
                    prod.composicao,
                    indice
                  )
                "
              >
                Composições
              </button>
            </div>
            <InputSelect
              label="Itens"
              ref="refItens"
              :items="products"
              valueField="id"
              textField="nome"
              :dataSelect.sync="dataBudget.products[indice].idProduto"
              :openCreateModal="
                () =>
                  handleModalCreateProduct(
                    'modalCreateProduct',
                    dataBudget.products[indice].idProduto
                  )
              "
              @selectedIten="selectProductSetDefaultValue($event, indice)"
            />
          </div>

          <div style="width: 20%">
            <Input
              label="Observação"
              type="text"
              :dataInput.sync="dataBudget.products[indice].dadosAdicionais"
              :labelFixed="true"
            />
          </div>

          <div style="width: 5%">
            <InputQuant
              label="Qtd."
              ref="refQtd"
              type="text"
              :dataInput.sync="dataBudget.products[indice].quantidade"
              @calculateTotal="calculateTotalValue = { prod, indice }"
              :decimalPlaces="decimalPlaces"
            />
          </div>

          <div class="ajustMobile" style="width: 8%; position: relative">
            <InputMoney
              label="Vl. Unit"
              labelFixed
              ref="refVlUnit"
              type="text"
              :dataInput.sync="dataBudget.products[indice].valorUnitario"
              @calculateTotal="calculateTotalValue = { prod, indice }"
            />
          </div>

          <div class="ajustMobile" style="width: 8%">
            <InputMoney
              label="Vl. Total"
              labelFixed
              ref="valorTotal"
              type="text"
              isDisabled
              :dataInput.sync="calculateTotalValue"
            />
          </div>

          <div class="ajustMobile" style="width: 10%">
            <div
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                position: relative;
              "
            >
              <InputMoney
                v-if="!discountIsCheked"
                label="Desc"
                labelFixed
                type="text"
                :dataInput.sync="dataBudget.products[indice].desconto"
                @calculateTotal="calculateTotalValue = { prod, indice }"
                ref="discount"
              />

              <InputPercentage
                v-else
                label="Desc"
                labelFixed
                type="text"
                :dataInput.sync="dataBudget.products[indice].desconto"
                @calculateTotal="calculateTotalValue = { prod, indice }"
                ref="discount"
              />

              <label
                ref="hideDiscount"
                style="position: absolute; right: 0px; height: 100%"
                class="switch col-sm-2 col-md-2 col-lg-2 col-xl-2"
              >
                <input
                  type="checkbox"
                  ref="typeDiscount"
                  @click="alterDiscountIsCheked"
                  @change="enableTooltipDiscount"
                  v-model="discountIsCheked"
                />
                <div class="slider">
                  <span>R$</span>
                  <span>%</span>
                </div>
              </label>
              <div
                style="display: none"
                ref="enableLabelApplyAll"
                class="tooltipDiscount"
              >
                <div class="tooltipItems">
                  <div>
                    <label for="apllyAll">Aplicar em todos</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="apllyAll"
                      v-model="discountToAllItems"
                      @change="alterAllChecksDiscount"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="contentIconRemoveItems"
            @click="handleRemoveItens(prod.idList, prod.id)"
          >
            <div>
              <b-icon-x-circle role="button" scale="1.3" />
            </div>
          </div>
          <div class="radio-input" v-if="prod.idProduto">
            <label>
              <input
                type="radio"
                :name="'value-radio-' + indice"
                value="P1"
                v-model="dataBudget.products[indice].tipoPreco"
                @change="updateProductValue(indice, 'P1', prod)"
              />
              <span>VL . 1</span>
            </label>
            <label>
              <input
                type="radio"
                :name="'value-radio-' + indice"
                value="P2"
                v-model="dataBudget.products[indice].tipoPreco"
                @change="updateProductValue(indice, 'P2', prod)"
              />
              <span>VL . 2</span>
            </label>
            <label>
              <input
                type="radio"
                :name="'value-radio-' + indice"
                value="P3"
                v-model="dataBudget.products[indice].tipoPreco"
                @change="updateProductValue(indice, 'P3', prod)"
              />
              <span>VL . 3</span>
            </label>
            <span class="selection"></span>
          </div>
        </b-row>
      </div>

      <b-row class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-end"
        >
          <button
            class="btnsBudget"
            @click="handleAddItensAndHandleAssignValues"
          >
            Adicionar mais itens
          </button>
        </div>
      </b-row>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="contentDataTotals col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="contentTotals">
            <span class="inputTotals">
              <p>Total de Itens (R$)</p>
              <input disabled type="text" v-model="totalOfItens" />
            </span>

            <span class="inputTotals">
              <p>Desconto (R$)</p>
              <input disabled type="text" v-model="totalOfDiscount" />
            </span>

            <span class="dashed"></span>

            <span class="inputTotals contentFreteTotals">
              <p>Frete (R$)</p>
              <input
                style="color: #5c5855"
                type="text"
                :value="maskCurrency(dataBudget.frete)"
                @input="handleFreteMonetaryValue($event)"
              />
            </span>

            <span class="inputTotals contentFreteTotals">
              <p>Outros (R$)</p>
              <input
                style="color: #5c5855"
                type="text"
                :value="maskCurrency(dataBudget.outrosValores)"
                @input="handleOtherValuesMonetaryValue($event)"
              />
            </span>

            <span class="inputTotals">
              <p>Total do Orçamento (R$)</p>
              <input disabled type="text" v-model="totalValue" />
            </span>
          </div>
        </div>
      </b-row>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações Gerais</p>

            <p class="observacoes">
              (Insira aqui, observações que serão importantes para você)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="dataBudget.dadosAdicionais"
          />
        </div>

        <div class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações de Nota Fiscal</p>

            <p class="observacoes">
              (Insira aqui, observações que sairão na Nota Fiscal do cliente)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="dataBudget.observacoesNf"
          />
        </div>
      </b-row>

      <b-row class="mb-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12 contentButtonsActions"
        >
          <div>
            <button
              v-if="
                dataBudget.id &&
                dataBudget.idSituacao !==
                  getSpecificSituantionId(saleTypeSituation.cancelado)
              "
              style="width: 175px"
              class="btnsBudget btnCancelBudget"
              @click="cancelBudget"
            >
              Cancelar Orçamento
            </button>

            <button
              class="btnsBudget btnReopenBudget"
              v-if="
                dataBudget.id &&
                dataBudget.idSituacao ===
                  getSpecificSituantionId(saleTypeSituation.cancelado)
              "
              @click="reOpenBudget"
            >
              Reabrir Orçamento
            </button>
          </div>

          <div style="display: flex; gap: 10px">
            <div>
              <button
                style="width: 175px"
                class="btnsBudget btnConfirm"
                @click="handleDataToSendModalBudget"
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </b-row>
    </div>
    <ModalBudget
      :dataBudget="dataBudget"
      :dataBudgetForModalBudget="dataBudgetForModalBudget"
      :totals="objectTotal"
      @budgetSaved="dataBudgetOfTheBudgetModal = $event"
      @clearAllBudget="clearAllBudget = $event"
    />
    <ModalCreateCustomer
      @getAllNewCustomer="handleCreatedNewCustomer"
      :idCustomerFromSearch="dataBudget.idCliente"
    />
    <ModalCreateEmployees
      @getAllNewEmployee="createdNewEmployee = $event"
      :idEmployeeFromSearch="dataBudget.idFuncionario"
    />
    <ModalCreateProducts
      @getAllNewProduct="createdNewProducts = $event"
      :idProductFromSearch="idProductFromSearch"
    />
    <ModalComposition
      :compositions="compositionItems"
      :prodIndice="prodIndice"
      :idProduct="idProduct"
      :forceUpdateSelect="toggleToUpdate"
      @findCompositions="handleItemFromModalComposition($event)"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import api from "../../services/axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import ModalCreateCustomer from "../Modals/ModalCreateCustomer.vue";
import ModalCreateEmployees from "../Modals/ModalCreateEmployees.vue";
import ModalCreateProducts from "../Modals/ModalCreateProduct.vue";
import ModalBudget from "./Modal-Budget.vue";
import ModalComposition from "../Sale/Modal-Composition.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import Input from "../UI/CustomInputs/Input.vue";
import InputQuant from "../UI/CustomInputs/InputQuant.vue";
import InputMoney from "../UI/CustomInputs/InputMoney.vue";
import InputPercentage from "../UI/CustomInputs/InputPercentage.vue";
import InputInfinit from "../../components/UI/CustomInputs/InputInfinit.vue";

import ServiceCustomer from "../../services/serviceCustomer";
import ServiceProducts from "../../services/serviceProducts";
import ServiceProductSale from "../../services/serviceProductSale";
import ServiceSituation from "../../services/serviceSituation";
import ServiceSale from "../../services/serviceSale";

import { INITIAL_STATE_PRODUCTS } from "../../initialStates/sale";
import { SaleTypeSituation } from "../../utils/enums/SalesSituationType";
import { mascaraMoeda, maskCurrency } from "../../utils/maskMoney";
import toastAlertErros from "../../utils/toastAlertErros";

export default {
  components: {
    ModalBudget,
    InputSelect,
    Input,
    InputMoney,
    InputPercentage,
    InputInfinit,
    InputQuant,
    ModalCreateCustomer,
    ModalCreateEmployees,
    ModalCreateProducts,
    ModalComposition,
  },
  data() {
    return {
      dataBudget: {
        id: "",
        idCliente: "",
        idFuncionario: "",
        idSituacao: "",
        data: moment().format("YYYY-MM-DD"),
        dataVencimento: null,
        dadosAdicionais: "",
        observacoesNf: "",
        status: "Orçamento",
        tipo: "Orçamento", //Venda, Frente de caixa, Venda MTQ
        frete: 0,
        outrosValores: 0,
        tipoDesconto: "Valor Monetário", // ou Porcentagem
        products: [
          {
            id: "",
            idVenda: "",
            idFornecedor: "",
            idProduto: "",
            quantidade: "0",
            altura: "",
            largura: "",
            metragem: "",
            porcentagemPerda: "",
            valorUnitario: 0,
            valorTotal: 0,
            desconto: 0,
            dadosAdicionais: "",
            tipoProduto: "normal",
            composicao: [],
            tipoPreco: "P1",
          },
        ],
      },
      dataCustomers: [],
      dataEmployee: [],
      products: [],
      providers: [],
      comissao: "",
      productUnitaryValue: "",
      totalParcelas: 1,
      intervaloDias: "",
      allPaymentsTypes: [],
      allSituations: [],
      total: "",
      totalOfItens: "R$ 0,00",
      totalOfDiscount: "R$ 0,00",
      additionalValues: "R$ 0,00",
      totalValue: "R$ 0,00",
      discountToAllItems: false,
      discountToIndividuallyItem: false,
      discountIsCheked: false,
      dataBudgetForModalBudget: {
        customerSelected: {
          nome: "",
        },
        employeeSelected: {
          nomeFuncionario: "",
        },
        formattedProductsBudget: [],
        data: "",
      },
      contaRecebimento: "",
      paymentSelected: "",
      dataBudgetOfTheBudgetModal: {},
      numberBudget: "",
      saleTypeSituation: SaleTypeSituation,
      createdNewCustomer: false,
      createdNewEmployee: null,
      createdNewProducts: null,
      clearAllBudget: false,
      maskCurrency: maskCurrency,
      compositionItems: [],
      prodIndice: null,
      idProduct: "",
      idProductFromSearch: "",
      toggleToUpdate: false,
      selectedItem: {
        id: null,
        idx: null,
      },
    };
  },
  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_SALE", "DATA_BUDGET_FOR_SALE"]),
    handleAddItensAndHandleAssignValues() {
      this.addMoreItens();
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
    },

    async addMoreItens() {
      const idList = uuidv4();
      const newProducts = { ...INITIAL_STATE_PRODUCTS, idList };
      this.dataBudget.products.push(newProducts);
      this.enableChangeDiscount();
      this.enableTooltipDiscount();

      this.cleanTotalValueLastProduct();
    },

    async cleanTotalValueLastProduct() {
      await this.$nextTick();
      this.$refs.valorTotal[this.dataBudget.products.length - 1].valueInput =
        "";
    },

    assignTotalItensValues() {
      const valorTotal = this.dataBudget.products.reduce(
        (oldValue, product) => oldValue + product.valorTotal,
        0
      );

      this.totalOfItens = parseFloat(valorTotal).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    assignTotalDiscount() {
      if (this.discountIsCheked) {
        if (this.discountToAllItems) {
          return (this.totalOfDiscount =
            this.calculateTotalDiscountWithApllyToAll());
        }

        if (!this.discountToAllItems && this.discountIsCheked) {
          return (this.totalOfDiscount = this.calculateTotalDiscount());
        }
      }

      this.totalOfDiscount = this.calculateTotalDiscountWithMonetaryValue();
    },

    assignTotalValue() {
      const formattedTotal = mascaraMoeda(this.totalOfItens);
      const formattedTotalDiscount = mascaraMoeda(this.totalOfDiscount);
      const frete = this.dataBudget.frete;
      const outrosValores = this.dataBudget.outrosValores;

      const newTotal =
        formattedTotal -
        formattedTotalDiscount +
        (parseFloat(frete) || 0) +
        (parseFloat(outrosValores) || 0);

      this.totalValue = newTotal.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscountWithApllyToAll() {
      for (let i = 0; i < this.dataBudget.products.length; i++) {
        this.dataBudget.products[i].desconto =
          this.dataBudget.products[0].desconto;
      }

      const formattedTotalItens = mascaraMoeda(this.totalOfItens);

      const discountTotal =
        (formattedTotalItens * this.dataBudget.products[0].desconto) / 100;

      return discountTotal.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscount() {
      const discounts = this.dataBudget.products.map((prod) => {
        const desc = prod.desconto;
        const totalValue = prod.valorTotal;

        return (totalValue * desc) / 100;
      });

      const totalValueWithDiscount = discounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalValueWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscountWithMonetaryValue() {
      const monetaryDiscounts = this.dataBudget.products.map((prod) => {
        if (prod.desconto) {
          const desc = prod.desconto;

          return parseFloat(desc);
        }

        return 0;
      });

      const totalMonetaryWithDiscount = monetaryDiscounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalMonetaryWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    async handleRemoveItens(idList, idItem) {
      if (idItem) {
        try {
          await this.removeItens(idItem);
        } catch (error) {
          return this.$toast.open({
            message:
              `${error.response.data.message}` ||
              "Ocorreu um erro ao remover o produto!",
            type: "warning",
          });
        }
      }

      this.removeItensInArray(idList);
    },

    async removeItensInArray(idList) {
      if (this.dataBudget.products.length > 1) {
        this.dataBudget.products = this.dataBudget.products.filter(
          (item) => item.idList !== idList
        );

        await this.$nextTick();
        this.$refs.hideDiscount[0].style.display = "block";

        if (this.discountToAllItems) {
          this.$refs.enableLabelApplyAll[0].style.display = "block";
        }

        this.assignTotalItensValues();
        this.assignTotalDiscount();
        return this.assignTotalValue();
      }

      const newIdList = uuidv4();
      this.dataBudget.products = [{ ...INITIAL_STATE_PRODUCTS, newIdList }];
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
      this.cleanTotalValueLastProduct();
    },

    async removeItens(id) {
      await ServiceProductSale.delete(id);

      return this.$toast.open({
        message: "Produto removido",
        type: "success",
      });
    },

    async cancelBudget() {
      try {
        await ServiceSale.cancel(this.dataBudget.id);

        this.dataBudget.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.cancelado
        );

        return this.$toast.open({
          message: "Orçamento cancelado!",
          type: "success",
        });
      } catch (error) {
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async reOpenBudget() {
      try {
        await ServiceSale.reOpen(this.dataBudget.id);

        this.dataBudget.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.emAberto
        );

        return this.$toast.open({
          message: "Orçamento reaberto!",
          type: "success",
        });
      } catch (error) {
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async fillCustomerComboBoxWithPaginate(id, q) {
      try {
        return await ServiceCustomer.fillComboBoxWithPaginate(id, q);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os clientes",
          type: "info",
        });
      }
    },
    async handleSituation() {
      const allSituation = await this.getAllSituation();
      this.toAssignDefaultSituation(allSituation);
    },

    async getAllSituation() {
      try {
        const result = await ServiceSituation.getAllSituation();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    async listEmployeesSelectBox() {
      try {
        const { data } = await api.get("/employees/combobox/fill");
        this.dataEmployee = data.data;
      } catch (error) {
        console.log(error.response);
      }
    },

    async getProdutos() {
      const { data } = await api.get("/products");
      this.products = data.data;
    },

    async getProviders() {
      const { data } = await api.get("/providers/fill/combobox");
      this.providers = data;
    },

    async getProductById(idProduct) {
      const data = await ServiceProducts.getProductById(idProduct);
      return data;
    },

    async updateProductValue(indice, selected, prod) {
      const newProd = this.products.find(
        (product) => product.id === prod.idProduto
      );

      const valueMapping = {
        P1: newProd.valorVenda,
        P2: newProd.valorVenda2,
        P3: newProd.valorVenda3,
      };

      const currencyValueProduct = valueMapping[selected];

      this.dataBudget.products[indice].valorUnitario = currencyValueProduct;

      if (this.$refs.refVlUnit[indice]) {
        this.$refs.refVlUnit[indice].valueInput = currencyValueProduct;
      }
      this.calculateTotalValue = { prod, indice };
    },

    async selectProductSetDefaultValue(idProduct, indice) {
      const product = await this.getProductById(idProduct);

      if (this.$refs.refVlUnit[indice]) {
        this.$refs.refVlUnit[indice].valueInput = product.valorVenda;
      }

      this.dataBudget.products[indice].composicao = product.composicao.map(
        (item) => {
          const newInitialState = {
            ...INITIAL_STATE_PRODUCTS,
            idList: uuidv4(),
            dadosAdicionais: item.dadosAdicionais,
            idProduto: item.idProduto,
            tipoProduto: "composicao",
            tipo: "Orçamento",
            quantidade: item.quantidade,
          };

          Reflect.deleteProperty(item, "idRefProduto");

          return {
            ...item,
            ...newInitialState,
          };
        }
      );

      this.dataBudget.products.forEach((prod) => {
        const amount = prod.quantidade;
        const unitaryValue = prod.valorUnitario;
        const total = amount * unitaryValue;
        this.dataBudget.products[indice].valorTotal = total;
        this.$refs.valorTotal[indice].valueInput = total;
      });

      this.$forceUpdate();
      this.assignTotalItensValues();
      this.assignTotalValue();
    },

    cancelReplaceAll() {
      this.selectedItem = {
        id: null,
        idx: null,
      };

      if (this.$refs.replaceAll && this.$refs.replaceAll.length) {
        this.$refs.replaceAll.forEach((refItem) => {
          refItem.style.display = "block";
        });
      }
    },

    applyReplaceAll() {
      this.dataBudget.products.forEach((prod, indice) => {
        if (
          this.selectedItem.id !== prod.idProduto &&
          indice !== this.selectedItem.idx
        )
          return;

        const productId =
          this.dataBudget.products[this.selectedItem.idx].idProduto;
        const unitValue =
          this.dataBudget.products[this.selectedItem.idx].valorUnitario;

        prod.idProduto = productId;
        prod.valorUnitario = unitValue;

        this.$refs.refItens[indice].valueItems = productId;
        this.$refs.refVlUnit[indice].valueInput = unitValue;

        this.calculateTotalValue = { prod, indice };
      });

      this.cancelReplaceAll();
    },

    handleItemSelected(id, idx) {
      this.selectedItem = {
        id,
        idx,
      };

      if (this.$refs.replaceAll && this.$refs.replaceAll.length) {
        this.$refs.replaceAll.forEach((refItem, index) => {
          if (idx === index) return;

          refItem.style.display = "none";
        });
      }
    },

    handleDuplicate(product) {
      this.dataBudget.products.push({
        ...product,
        id: "",
        idList: uuidv4(),
      });
      const lastIdx = this.dataBudget.products.length - 1;
      const lastItem = this.dataBudget.products[lastIdx];

      this.$nextTick(() => {
        this.$refs.refItens[lastIdx].valueItems = product.idProduto;
        this.calculateTotalValue = { prod: lastItem, indice: lastIdx };
      });

      this.enableChangeDiscount();
    },

    handleDataToSendModalBudget() {
      this.assingValuesBudgetForModalBudget();
      this.openModals("modalBudget");
    },

    handleModalCreateProduct(nameModal, idProduct) {
      this.idProductFromSearch = idProduct;
      this.openModals(nameModal);
    },

    assingValuesBudgetForModalBudget() {
      const productsWithName = this.dataBudget.products.map((prod) => {
        return {
          ...prod,
          nome: "",
        };
      });

      this.dataBudgetForModalBudget.formattedProductsBudget =
        productsWithName.map((prodSale) => {
          if (prodSale.idProduto) {
            return {
              ...prodSale,
              nome: this.products.filter(
                (prod) => prod.id === prodSale.idProduto
              )[0].nome,
            };
          }

          return prodSale;
        });

      this.dataBudgetForModalBudget.customerSelected.nome =
        this.dataCustomers.filter(
          (customer) => customer.id === this.dataBudget.idCliente
        )[0]?.nome;

      this.dataBudgetForModalBudget.employeeSelected.nomeFuncionario =
        this.dataEmployee.filter(
          (employee) => employee.id === this.dataBudget.idFuncionario
        )[0]?.nomeFuncionario;

      this.dataBudgetForModalBudget.data = this.dataBudget.data;
      this.dataBudgetForModalBudget.formattedProductsBudget;
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    enableTooltipDiscount() {
      if (
        this.$refs.typeDiscount[0].checked &&
        this.dataBudget.products.length > 1
      ) {
        return (this.$refs.enableLabelApplyAll[0].style.display = "block");
      }

      this.discountToAllItems = false;
      this.$refs.enableLabelApplyAll[0].style.display = "none";
    },

    alterDiscountIsCheked() {
      this.discountIsCheked = !this.discountIsCheked;
      if (this.discountIsCheked) {
        this.dataBudget.tipoDesconto = "Porcentagem";
        this.discountToIndividuallyItem = true;
        this.handleCalculateTotalsValues();
      } else {
        this.dataBudget.tipoDesconto = "Valor Monetário";
        this.discountToIndividuallyItem = false;
        this.handleCalculateTotalsValues();
      }
    },

    async enableChangeDiscount() {
      const refsDiscount = this.$refs.hideDiscount;
      await this.$nextTick();
      for (let i = 1; i < refsDiscount.length; i++) {
        this.$refs.hideDiscount[i].style.display = "none";
      }
    },

    alterAllChecksDiscount() {
      if (this.discountToAllItems) {
        const typesDiscount = this.$refs.typeDiscount;
        for (var i = 0; i < typesDiscount.length; i++) {
          this.$refs.typeDiscount[i].checked = true;
        }

        this.handleCalculateTotalsValues();
      }
    },

    async handleValuesAfterEditBudget() {
      this.handleBudgetValues();
      this.assingProductsToBudget();

      await this.enableChangeDiscount();
    },

    handleBudgetValues() {
      this.handleBillsIfAny();
      this.handleBudgetProductsIfAny();
      this.assignDataSearchFromBudget();
    },

    handleBillsIfAny() {
      return Reflect.deleteProperty(this.dataSearchFromSale, "bills");
    },

    handleBudgetProductsIfAny() {
      if (!this.dataSearchFromSale?.products?.length) {
        Reflect.deleteProperty(this.dataSearchFromSale, "products");
      }
    },

    handleDataCustomers(event) {
      this.dataCustomers = [event];
    },

    handleCreatedNewCustomer() {
      this.createdNewCustomer = !this.createdNewCustomer;
    },

    assignDataSearchFromBudget() {
      Object.assign(this.dataBudget, this.dataSearchFromSale);
      if (this.dataBudget.tipoDesconto === "Porcentagem") {
        this.discountIsCheked = true;
        this.$nextTick(() => {
          this.$refs.enableLabelApplyAll[0].style.display = "block";
        });
        this.assignTotalDiscount();
      }

      this.numberBudget = this.dataSearchFromSale.numero;

      this.$refs.refResponsavel.valueItems =
        this.dataSearchFromSale.idFuncionario;
      this.$refs.data.valueInput = this.dataSearchFromSale.data;
      this.$refs.dataVencimento.valueInput =
        this.dataSearchFromSale.dataVencimento;
    },

    assingProductsToBudget() {
      this.dataBudget.products = this.dataBudget.products
        .map((item) => {
          return {
            ...item,
            idList: uuidv4(),
          };
        })
        .sort((a, b) => a.quantidade - b.quantidade);

      this.$nextTick(() => {
        for (let i = 0; i < this.dataBudget.products.length; i++) {
          this.$refs.refItens[i].valueItems =
            this.dataBudget.products[i].idProduto;

          this.$refs.refQtd[i].valueInput =
            this.dataBudget.products[i].quantidade;

          this.$refs.refVlUnit[i].valueInput =
            this.dataBudget.products[i].valorUnitario;

          this.$refs.discount[i].valueInput =
            this.dataBudget.products[i].desconto;

          this.$refs.valorTotal[i].valueInput =
            this.dataBudget.products[i].valorTotal;
        }
      });
    },

    toAssignDefaultSituation(situations) {
      this.allSituations = situations
        .filter((situation) => {
          if (
            [SaleTypeSituation.emAberto, SaleTypeSituation.cancelado].includes(
              situation.nome
            )
          ) {
            return situation;
          }
        })
        .reverse();

      if (!this.dataBudget.id) {
        this.dataBudget.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.emAberto
        );
      }
    },

    getSpecificSituantionId(saleTypeSituation) {
      const situation = this.allSituations.find(
        (situation) => situation.nome === saleTypeSituation
      );

      return situation?.id;
    },

    getNameSituantionById(idSituation) {
      const situation = this.allSituations.find(
        (situation) => situation.id === idSituation
      );

      return situation?.nome?.toUpperCase();
    },

    handleFreteMonetaryValue(event) {
      this.dataBudget.frete = mascaraMoeda(event.target.value);
    },

    handleOtherValuesMonetaryValue(event) {
      this.dataBudget.outrosValores = mascaraMoeda(event.target.value);
    },

    handleCalculateTotalsValues() {
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
    },

    clearAllRefs() {
      this.$refs.refResponsavel.valueItems = "";
      this.$refs.dataVencimento.valueItems = null;
      this.$refs.valorTotal.valueItems = "0,00";
      this.$refs.refVlUnit.valueItems = "0,00";
      this.$refs.refItens.valueItems = "";
    },

    handleShowCompositionItem(idProduct, composition, prodIndice) {
      if (idProduct) {
        this.compositionItems = composition;
        this.prodIndice = prodIndice;
        this.idProduct = idProduct;
        this.toggleToUpdate = !this.toggleToUpdate;
        this.$bvModal.show("modalProductSaleComposition");
      }
    },

    handleItemFromModalComposition(payload) {
      this.dataBudget.products[payload.indice].composicao = payload.composition;
    },
  },
  mounted() {
    const idList = uuidv4();
    this.dataBudget.products[0].idList = idList;
    this.listEmployeesSelectBox();
    this.getProdutos();
    this.getProviders();
    this.handleSituation();

    if (
      !this.dataBudget.dadosAdicionais &&
      this.dataConfig &&
      Object.keys(this.dataConfig.orcamento).length &&
      this.dataConfig.orcamento.dados_adicionais_padrao
    ) {
      this.dataBudget.dadosAdicionais =
        this.dataConfig.orcamento.dados_adicionais_padrao;
    }

    if (this.dataSearchFromSale.idCliente) {
      this.handleValuesAfterEditBudget();
      this.SET_DATA_SEARCH_FROM_SALE({});
    }

    this.assignTotalItensValues();
    this.assignTotalDiscount();
    this.assignTotalValue();
  },
  filters: {
    moment(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  watch: {
    applyDiscountToAllItems() {
      if (this.discountToAllItems) {
        const refsDiscount = this.$refs.discount;
        for (var i = 0; i < refsDiscount.length; i++) {
          this.$refs.discount[i].valueInput =
            this.dataBudget.products[0].desconto;

          this.dataBudget.products[i].desconto =
            this.$refs.discount[i].valueInput;
        }
      }
    },

    freteToAdditionalValues() {
      this.additionalValues = "R$ 0,00";
      const formattedFrete = this.dataBudget.frete;

      if (formattedFrete) {
        this.additionalValues = parseFloat(formattedFrete).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      this.assignTotalValue();
    },

    otherValuesToAdditionalValues() {
      this.otherValues = "R$ 0,00";
      const formattedOtherVales = this.dataBudget.outrosValores;

      if (formattedOtherVales) {
        this.otherValues = parseFloat(formattedOtherVales).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      this.assignTotalValue();
    },

    dataBudgetOfTheBudgetModal() {
      if (this.dataBudgetOfTheBudgetModal.id) {
        this.dataBudget.id = this.dataBudgetOfTheBudgetModal.id;
      }

      if (this.dataBudgetOfTheBudgetModal.productsOfSale) {
        const newProductsFromTable =
          this.dataBudgetOfTheBudgetModal.productsOfSale.map((prod, index) => {
            return {
              ...prod,
              idList: this.dataBudget.products[index].idList,
            };
          });

        this.dataBudget.products = newProductsFromTable;
      }
    },

    createdNewEmployee() {
      this.listEmployeesSelectBox();
    },
    createdNewProducts() {
      this.getProdutos();
    },

    clearAllBudget() {
      this.clearAllRefs();
      this.comissao = "";
      this.dataBudget.id = "";
      this.clearAllBudget = false;
      this.dataBudget.idCliente = "";
      this.dataBudget.dataVencimento = "DD/MM/YYYY";
      this.dataBudget.idFuncionario = "";
      this.numberBudget = "";
      this.dataBudgetOfTheBudgetModal.number = "";
      this.dataBudget.dadosAdicionais = "";
      this.dataBudget.products = [
        {
          id: "",
          idVenda: "",
          idFornecedor: "",
          idProduto: "",
          quantidade: "1",
          altura: "",
          largura: "",
          metragem: "",
          porcentagemPerda: "",
          valorUnitario: 0,
          valorTotal: 0,
          desconto: 0,
          dadosAdicionais: "",
        },
      ];
      this.dataBudget.frete = 0;
      this.totalOfItens = "R$ 0,00";
      this.totalOfDiscount = "R$ 0,00";
      this.additionalValues = "R$ 0,00";
      this.totalValue = "R$ 0,00";
    },
  },
  computed: {
    ...mapState({
      dataSearchFromSale: (state) => state.dataSearchFromSale,
      dataConfig: (state) => state.dataConfig,
    }),
    calculateTotalValue: {
      get() {
        return this.total;
      },
      set({ prod, indice }) {
        const amount = prod.quantidade;
        const unitaryValue = prod.valorUnitario;
        const totalValue = amount * unitaryValue;

        if (!this.$refs.valorTotal[indice]) return;

        this.dataBudget.products[indice].valorTotal = totalValue;

        this.total = totalValue !== 0 ? totalValue : "0,00";

        this.$refs.valorTotal[indice].valueInput = this.total;

        this.assignTotalItensValues();
        this.assignTotalDiscount();
        this.assignTotalValue();
      },
    },
    decimalPlaces() {
      if (Object.keys(this.dataConfig).length) {
        return Number(this.dataConfig?.venda_mtq?.casas_decimais);
      }

      return 2;
    },
    applyDiscountToAllItems() {
      return this.dataBudget.products[0].desconto;
    },
    freteToAdditionalValues() {
      return this.dataBudget.frete;
    },
    otherValuesToAdditionalValues() {
      return this.dataBudget.outrosValores;
    },
    objectTotal() {
      return {
        totalItems: this.totalOfItens,
        additionalValues: this.additionalValues,
        otherValues: this.otherValues,
        totalDiscount: this.totalOfDiscount,
        total: this.totalValue,
      };
    },
    alterColorSituation() {
      const situation = this.allSituations.find(
        (situation) => situation.id === this.dataBudget.idSituacao
      );

      if (situation && situation.nome === SaleTypeSituation.emAberto) {
        return "";
      }

      return "rgb(198, 32, 32)";
    },
  },
  beforeDestroy() {
    this.SET_DATA_SEARCH_FROM_SALE({});
  },
};
</script>

<style scoped>
.containerGeralBudget {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 2rem;
  margin-left: 2rem;
}

p {
  margin: 0;
}

.titleHeader {
  margin-left: 16px;
}

.btnsBudget {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 30px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.btnCancelBudget {
  background-color: #ff3131;
}

.btnReopenBudget {
  background-color: #cac64e;
}

.btnsComposition {
  border-radius: 5px;
  width: auto;
  height: 20px;
  border: none;
  color: white;
  font-size: 9px;
  background-color: #a8a8a8;
  transition: ease-in all 0.1s;
}

.btnsComposition:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.353);
  background-color: rgb(116, 116, 116);
  transition: ease-in all 0.1s;
}

.btnReplaceAll {
  border-radius: 5px;
  background-color: #a8a8a8;
  border: none;
  color: white;
  font-size: 12px;
  width: 20px;
  height: 20px;
  padding: 0.2rem;
  cursor: pointer;
  transition: ease-in all 0.1s;
}

.btnReplaceAll:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.353);
  background-color: rgb(116, 116, 116);
  transition: ease-in all 0.1s;
}

.isDisabled {
  background-color: #e1e1e1;
}

.btnConfirm {
  background-color: whitesmoke;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.inputDesc {
  width: 8.3% !important;
}

.labelInputs p {
  font-weight: 100 !important;
  color: #00395d;
  padding: 0;
}

.iconPlus {
  position: absolute;
  right: 20px;
  top: 0;
}

.switch {
  top: -28px;
  font-size: 14px;
  position: relative;
  display: inline-block;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #d9d9d9;
  color: #ffffff;
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 50px !important;
  height: 90%;
  font-size: 8px;

  font-weight: 600;
}

.slider:before {
  position: absolute;
  content: "R$";
  font-size: 10px;
  height: 82%;
  width: 44%;
  left: 6%;
  border-radius: 50px;
  background-color: #00aa62;
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
  font-weight: bold;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.switch input:checked + .slider {
  background-color: #d9d9d9;
  color: white;
}

.switch input:checked + .slider:before {
  content: "%";
  background-color: #00aa62;
  transform: translateX(100%);
  color: white;
}

.switch input {
  display: none;
}

.contentDataTotals {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.inputTotals {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #666360;
}

.dashed {
  border-bottom: 1px dashed #b1aaa5;
  width: 100%;
  margin: 10px 0;
}

.infinite {
  padding-right: 30px;
}

.inputTotals input {
  border: none;
  outline: none;
  text-align: end;
  padding-right: 5px;
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 30px;

  border: solid 1px #d9d9d9;
}

.inputTotals p {
  width: 200px;
}

.contentTotals {
  width: 370px;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  font-size: 14px;
  gap: 5px;
}

.contentFreteTotals input {
  background-color: white !important;
  border: solid 1px #d9d9d9;
}

.infoValue {
  gap: 5px;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.radio-input input {
  display: none;
}

.radio-input {
  position: absolute;
  top: 40px;
  display: flex;
  --container_width: 130px;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  color: #000000;
  width: var(--container_width);
  overflow: hidden;
  border: 1px solid rgba(53, 52, 52, 0.226);
}

.radio-input label {
  width: 100%;
  padding: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 11px;
  margin-bottom: 0px !important;
}

.selection {
  display: none;
  position: absolute;
  height: 100%;
  width: calc(var(--container_width) / 3);
  z-index: 0;
  left: 0;
  top: 0;
  transition: 0.15s ease;
}

.radio-input label:has(input:checked) {
  color: #fff;
}

.radio-input label:has(input:checked) ~ .selection {
  background-color: #3a4750;
  display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 0 / 3));
}

.radio-input label:nth-child(2):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 1 / 3));
}

.radio-input label:nth-child(3):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 2 / 3));
}

.itemsBudget {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 16px;
  margin-right: 16px;
  width: 100%;
}

.observacoes {
  font-size: 12px;
  color: #7d7d7d;
  font-style: italic;
}

.title {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.contentIconRemoveItems {
  position: absolute;
  right: 60px;
  color: rgb(255, 51, 51);
}

.tooltipDiscount {
  z-index: 3;
  width: 137px;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  color: black;
  border-radius: 5px;
  top: -30px;
  right: 40px;
}

.tooltipItems {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.responsiveItems {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}

@media screen and (max-width: 1300px) {
  .responsiveItems {
    max-width: 1336px;
    overflow-x: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tooltipDiscount {
    right: 100px;
  }
}

@media screen and (max-width: 768px) {
  .paymentInterval {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 575px) {
  .receiptAccount {
    margin-top: 1rem !important;
  }
}

.contentItens {
  margin-top: 1.5rem !important;
}

.contentButtonsActions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
}

.inputSelectSituation {
  border: solid 1px #d5d3d3;
  padding: 3px;
  border-radius: 0.25rem;
  outline: none;
  color: #5c5855;
  font-size: 12px;
}

.obsGeneral {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .containerGeralBudget {
    margin-left: 0rem;
  }

  .contentIconRemoveItems {
    right: 10px;
  }

  .responsiveItems {
    padding-top: 20px;
    padding-bottom: 10px;
    margin-top: 0rem !important;
  }

  .itemsBudget {
    margin-left: 0px;
    margin-right: 0px;
  }

  .switch {
    top: -37px;
  }

  .btnsBudget {
    width: 140px;
    margin-top: 15px;
    font-size: 10px;
  }

  .contentTotals {
    font-size: 11px;
  }

  .col-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12,
  .col-xl-12 {
    padding-right: 0px;
    padding-left: 10px;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 23%;
    max-width: 23%;
  }
  .col-md-5 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 23%;
    max-width: 23%;
  }
}

@media screen and (max-width: 700px) {
  .containerGeralBudget {
    margin-left: 0rem;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 15px;
  }

  .infinite {
    padding-right: 15px;
  }

  .row {
    gap: 20px;
  }

  .responsiveItems {
    padding-top: 25px;
    padding-bottom: 5px;
    margin-top: 0rem !important;
  }

  .inputTotals {
    gap: 10px;
  }

  .obsGeneral {
    width: 80%;
  }

  .itemsBudget {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px !important;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    flex-wrap: nowrap;
  }

  .contentIconRemoveItems {
    right: -20px;
  }

  .switch {
    top: -35px;
    left: -5px !important;
  }
  .ajustMobile {
    width: 13% !important;
  }
}
</style>
